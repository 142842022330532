import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
import checkWithdraw from "./system/auth/canWithdraw.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/web/",
    component: () => import("./views"),
    redirect: "/web/homepage",
    children: [
      {
        path: "/web/homepage",
        beforeEnter: checkAuth,
        component: () => import("./views/home/homePage"),
      },
      // {
      //   path: "/web/wallet",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/wallet/record/record_page"),
      // },

      {
        path: "/web/package",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/subscription/trade/trade"),
      },
      {
        path: "/web/team",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/team/memberTree"),
      },
      // {
      //   path: "/web/manage",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/manage"),
      // },
      {
        path: "/web/settings",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/wallet/record/commission"),
      },

    ]
  },
  // {
  //   path: "/web/invite_link",
  //   meta: { requiresAuth: true },
  //   beforeEnter: checkAuth,
  //   component: () => import("./views/manage/invitePage"),
  // },
  // {
  //   path: "/web/transaction",
  //   meta: { requiresAuth: true },
  //   component: () => import("./views/manage/transaction"),
  //   beforeEnter: checkAuth,
  //   children: [
  //     {
  //       path: "transaction",
  //       beforeEnter: checkAuth,
  //       component: () => import("./views/manage/transaction/transaction/transaction"),
  //     },
      // {
      //   path: "withdraw",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/withdraw"),
      // },
      // {
      //   path: "deposit",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/transaction/deposit"),
      // },
      //   path: "deposit",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/deposit/depositBox"),
      // },
      // {
      //   path: "setCoin",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setCoin"),
      // },
      // {
      //   path: "setBank",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setBank"),
      // },

  //   ]
  // },
  // {
  //   path: "/web/record",
  //   beforeEnter: checkAuth,
  //   component: () => import("./views/manage/record/record_page"),
  // },
  {
    path: "/web/profile",
    beforeEnter: checkAuth,
    component: () => import("./views/manage/profile"),
  },
  // {
  //   path: "/web/member_signup",
  //   name: 'member_signup', 
  //   beforeEnter: checkAuth,
  //   meta: { requiresAuth: true },
  //   component: () => import("./views/sessions/member_signup"),
  // },
  // {
  //   path: "/web/kyc",
  //   beforeEnter: checkAuth,
  //   component: () => import("./views/manage/profile/kyc"),
  // },
  {
    path: "/web/withdraw_info",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/transaction/withdraw"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "withdraw",
        meta: { requiresAuth: true },
        beforeEnter: checkWithdraw,
        component: () => import("./views/manage/transaction/withdraw/withdraw"),
      },
      {
        path: "setCoin",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setCoin"),
      },
      {
        path: "setBank",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setBank"),
      },

    ]
  },
  {
    path: "/web/security",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/security"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "change-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/change-password"),
      },

      {
        path: "change-sec-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/change-sec-password"),
      },

      {
        path: "forget-sec-password",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/security/forget-sec-password"),
      },


    ]
  },
  
  {
    path: "/web/rank-details",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/record/rank_details.vue"),
  },

  {
    path: "/web/withdraw",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/action/withdraw.vue"),
  },
  {
    path: "/web/redeemBoost",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/action/redeemBoost.vue"),
  },
  {
    path: "/web/vapi",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/action/vapi.vue"),
  },
  {
    path: "/web/sellBoost",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/action/sellBoost.vue"),
  },

  {
    path: "/web/set-sec-password",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/security/set-sec-password"),
  },

  {
    path: "/web/forget-password",
    component: () => import("./views/manage/security/forget-password"),
  },


  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/signIn"),
      },
      // {
      //   path: "aboutUs",
      //   component: () => import("./views/sessions/aboutUs"),
      // },
      // {
      //   path: "tnc",
      //   component: () => import("./views/sessions/tnc"),
      // },
    ]
  },
  {
    path: "/privacy_policy",
    component: () => import("./views/sessions/privacy_policy"),
  },
  {
    path: "/tnc",
    component: () => import("./views/sessions/tnc"),
  },
 
  {
    path: "/register",
    component: () => import("./views/sessions/signUp"),
  },
 
  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.


  if (from.path.includes('manage') ) {
    next();

  } else {
    if (to.path) {
      // Start the route progress bar.

      NProgress.start();
      NProgress.set(0.1);
    }
    next();

  }
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
